(function (window) {
    class VeissImageHandler {
        #_self;

        constructor(el) {
            this.#_self = el.closest('.container');
            this.#__init__();
        }

        #__init__() {
            this.#_fileLoadHandler();
        }

        // Images handler (filename display && image-previewer)
        #_fileLoadHandler() {
            const fileInput = this.#_self.querySelector('input');
            if (!fileInput) {
                return;
            }

            fileInput.addEventListener('change', () => {
                const [file] = fileInput.files;
                if (!file) {
                    return;
                }

                // Filename loader on file-input
                const filename = file.name;
                if (filename) {
                    const labels = this.#_self.querySelectorAll('label');
                    labels.forEach((label) => {
                        label.dataset.label = label.innerHTML;
                        label.innerHTML = filename;
                    });
                }

                // Image previewer
                const image = this.#_self.querySelector('img');
                if (image) {
                    image.src = URL.createObjectURL(file);
                }

                // Delete button
                const button = this.#_self.previousElementSibling;
                if (button) {
                    button.classList.remove('d-none');
                }
            });
        }
    }

    window.VeissImageHandler = VeissImageHandler;
})(window);
