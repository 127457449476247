/*Javascripts de Veiss*/
var indicadorJuegoViolento = [0, 1, 2, 3, 4];
var indicadorEspecialViolencia = [0, 1]; // si se modifica cambiar tambien en php (EvaluacionManager)
var cardLoading = new gestoLoading();
var deviceCheck = new deviceDetect();

$(document).ready(function () {

    // @TODO: deshabilitar envio formularios con "tecla enter"
    // ¡¡¡solo el submit del formulario!!! el siguiente codigo no funciona.
    ///////////////////////////////////////////////////////////////////////
    /*$(document).on('keypress', 'form', function(e){
        console.log(e.which)
        if (e.which == '13') {
            console.log(e);
            $(this).unbind( "submit" );
        }
    });*/

    //---------------------------------------------
    // Fix: campo nueva contraseña es completada por los navegadores.
    // Se vacia, atributo autocomplete no funciona
    //---------------------------------------------
    $('input[id$="_nuevaClave_first"]').val("");

    //---------------------------------------------
    // Inicializar datepickers
    //---------------------------------------------

    $('.js-datepicker').each(function () {

        var $this = $(this);

        if (deviceCheck.device == "desktoplg" || deviceCheck.device == "desktop") {

            $this.datepicker({
                locale: 'es-es',
                uiLibrary: 'bootstrap4',
                format: 'dd/mm/yyyy'
            });

        } else {

            var value = $this.val();
            var value_convert = dateCon.frontToBack(value);

            $this.val(value_convert);
            $this.attr("type", "date");
        }

    });

    //---------------------------------------------
    // Ejemplo loading card
    //---------------------------------------------

    $('#borrar').click(function (e) {

        e.preventDefault();

        var card = $('#formLayout .card').first();

        cardLoading.loadingOn(card);

    });

    $('#borrar2').click(function (e) {

        e.preventDefault();

        var card = $('#formLayout .card').first();

        cardLoading.loadingOff(card);

    });

    //---------------------------------------------
    // Menu Principal
    //---------------------------------------------

    $('.mm-toogle').click(function (e) {
        e.preventDefault();
        $('.menu-main-nav').toggleClass('menu-main-nav-active');
    });

    var $menuItem = $('.main-menu__link__children');
    var $subMenus = $('.main-menu-submenu');

    $menuItem.click(function (e) {

        e.preventDefault();

        var $submenu = $(this).siblings('.main-menu-submenu');

        if ($submenu.hasClass('active')) {

            $submenu.slideUp('slow');
            $submenu.removeClass('active');
            $(this).find('.main-menu__children').removeClass('main-menu__children_active');

        } else {

            $subMenus.removeClass('active');

            $submenu.addClass('active');
            $(this).find('.main-menu__children').addClass('main-menu__children_active');

            $subMenus.not('.active').slideUp('slow', function () {

                $subMenus.not('.active').siblings('.main-menu__link__children').find('.main-menu__children').removeClass('main-menu__children_active');
                $(this).removeClass('active');

            });

            $submenu.slideDown('slow');

        }

    });

    //---------------------------------------------
    // Filtros
    //---------------------------------------------

    $('.filters .card-header').click(function (e) {

        e.preventDefault();

        $(this).parent().find('.card-body').slideToggle('slow');

    })


    //---------------------------------------------
    // Flashes Out
    //---------------------------------------------
    /*
    var $flash = $('#flashes');

    if ($flash.length) {

        var flashOut = setTimeout(function () {

            $flash.fadeOut('slow');

        }, 3000);

    }
    */

    /*
    * si el partido se clasifica como violento, hay que seleccionar una de las
    * opciones de ACTITUDES Y CONDUCTA ASOCIADAS A JUEGO VIOLENTO (NO-DEPORTIVO)
    */
    $(document).on('submit', 'form[name="gesto_evaluacion"]', function(event) {
        // clasificacion del partido
        var clasificacion = $('#gesto_evaluacion_calificacionPartido').val();
        var checkedList = $('#conductasViolentas input:checked');

        /**
         * VALIDAR CONDUCTAS VIOLENTAS (notas < 5)
         */
        /*if (clasificacion < 5 && checkedList.length == 0) {

            if (!$('#conductasError').length) {
                var cardHeader = $('#conductasViolentas .card .card-header');
                var conductaError = $('<p id="conductasError" class="mt-2 text-danger">');
                conductaError.html("Selecciona por lo menos una de las opciones");
                cardHeader.append(conductaError);
            }

            $('html, body').animate({
                scrollTop: $("#conductasViolentas").offset().top
            }, 500);

            return false;
        }*/

        // 1.- borrar errores de los bloques de conductas violentas y deportivas.
        resetErroresBloquesConductas();

        /**
         * VALIDAR CONDUCTAS DEPORTIVAS (notas 9 y 10)
         */
        // 2.- validar conductas Violentas y Deportivas
        var errores = validarBloquesDeConductas();

        // 3.- mostrar los errores
        if (errores.length > 0) {
            firstErrorId = mostrarErroresBloquesConductas(errores);

            // moverse al primer error
            $('html, body').animate({
                scrollTop: $("#" + firstErrorId).offset().top
            }, 500);

            return false;
        }

        return true;
    });

    // Filtros evaluacion Arbitro Categoria
    $(document).on('change', "#gesto_evaluacion_categoria", function (event) {
        mostrarLoadingEvaluacion();
        $categoria = $(this).val();
        $.ajax({
            type: "POST",
            url: route_evaluarPartidoFilter,
            data: {
                'categoria': $categoria
            },
            success: function (data) {
                $('#gesto_evaluacion_competicion').html(data);
                // Resetear los selects de Jornada y Partido
                $('#gesto_evaluacion_jornada option').each(function () {
                    if ($(this).val() != 0) {
                        $(this).remove();
                    }
                });
                $('#gesto_evaluacion_partido option').each(function () {
                    if ($(this).val() != 0) {
                        $(this).remove();
                    }
                });
                $('#gesto_evaluacion_partido optgroup').each(function () {
                    $(this).remove();
                });
                ocultarLoading();
            }
        });
    });

    // Filtros evaluacion Arbitro Competicion
    $(document).on('change', "#gesto_evaluacion_competicion", function (event) {
        mostrarLoadingEvaluacion();
        $competicion = $(this).val();
        $.ajax({
            type: "POST",
            url: route_evaluarPartidoFilter,
            data: {
                'competicion': $competicion
            },
            success: function (data) {
                $('#gesto_evaluacion_jornada').html(data);
                // Resetear los selects de Jornada y Partido
                $('#gesto_evaluacion_partido option').each(function () {
                    if ($(this).val() != 0) {
                        $(this).remove();
                    }
                });
                $('#gesto_evaluacion_partido optgroup').each(function () {
                    $(this).remove();
                });
                ocultarLoading();
            }
        });
    });

    // Filtros evaluacion Arbitro Jornada
    $(document).on('change', "#gesto_evaluacion_jornada", function (event) {
        mostrarLoadingEvaluacion();
        $jornada = $(this).val();
        $.ajax({
            type: "POST",
            url: route_evaluarPartidoFilter,
            data: {
                'jornada': $jornada
            },
            success: function (data) {
                if (data) {
                    $('#gesto_evaluacion_partido').html(data);
                }
                ocultarLoading();
            }
        });
    });

    // cambio selector de federacion super admin
    $(document).on('change', "#gesto_formulario_selector_federacion", function () {
        var $form = $(this).closest('form');
        $form.submit();
    });

    // Aceptar politica de privacidad en la home para los entrenadores
    $(document).on('click', "#aceptar_politica_index", function (e) {
        $.ajax({
            type: "POST",
            url: route_aceptarPoliticaPrivacidad,
            data: {
                'aceptada': true
            },
            success: function (data) {
                if (data.reload == true) {
                    location.reload();
                } else {
                    alert(data.message);
                }
            }
        });
    });

    // Borrado: confirmacion!!
    $(document).on('click', ".delete-confirm", function (e) {

        e.preventDefault();
        $this = $(this);

        var url = $this.attr('href');
        var title = 'Borrado de registro';
        var content = '';

        if ($this.hasClass('delete-confirm-club')) {
            var nroEquipos = $this.data('equipos');
            var equipoString = (nroEquipos == 1) ? 'equipo' : 'equipos';
            content = '<p>El registro a borrar contiene <strong>' + nroEquipos + '</strong> ' + equipoString + '.</p><p>¿Estás seguro?</p>';
        } else if ($this.hasClass('delete-confirm-acta')) {
            title = 'Borrar acta';
            content = '<p>Vas a borrar el acta adjunto a este protocolo</p><p>¿Estás seguro?</p>';
        } else if ($this.hasClass('delete-confirm-informe')) {
            title = 'Borrar informe';
            content = '<p>Vas a borrar el informe adjunto a este taller formativo</p><p>¿Estás seguro?</p>';
        } else {
            content = '<p>Vas a borrar este registro</p><p>¿Estás seguro?</p>';
        }

        $('#delete-confirm').gestoModal({
            title: title,
            content: content,
            url: url
        });

    });

    // Privacidad Veiss
    var $modal = $('#veissModal'),
        $politica = $('#politica input'),
        $politicaSpan = $('#politica span'),
        privacidad = $politica.prop('checked');

    /**
     * 1. La política no está aceptada: mostrar modal.
     * 2. La política ya está aceptada: desmarcar.
     */
    $politica.click(function (e) {
        if (!privacidad) {
            e.preventDefault();
            modalPrivacidad();

            return;
        }

        toggleChecks(false);
    });

    /**
     * Los clicks en el label de la política de privacidad abren el modal.
     */
    $politicaSpan.click(function (e) {
        modalPrivacidad();
    });

    /**
     * Mostrar modal para la privacidad.
     */
    function modalPrivacidad() {
        $modal.veissModal({
            id: 'pol',
            buttons: [{
                id: 'polnook',
                class: 'btn btn-default modal-clear',
                click: function () {
                    toggleChecks(false);
                }
            }, {
                id: 'polok',
                class: 'btn btn-primary btn-serserve-on',
                click: function () {
                    toggleChecks(true);
                }
            }]
        });
    }

    /**
     * Cambiar el valor de la propiedad checked.
     */
    function toggleChecks(value) {
        privacidad = value;
        $politica.prop('checked', value);
    }

    //---------------------------------------------
    // INIT Filtros Administracion Evaluaciones
    //---------------------------------------------
    // comprobar el estado del boton de filtrar inicialmente
    filtrarStatus();

    // cambio del selector de activas
    $(document).on('change', "#administracion_filtros #form_activas", function (event) {
        mostrarLoadingById('.card-body');
        resetOptions('#administracion_filtros #form_jornada');
        resetOptions('#administracion_filtros #form_partido');
        $activas = $(this).val();
        $.ajax({
            type: "POST",
            url: route_administracionEvaluacionesFiltros,
            data: {
                'activas': $activas
            },
            success: function (data) {
                const competiciones = data.competiciones;
                addOptions('#administracion_filtros #form_competicion', competiciones);
                filtrarStatus();
                ocultarLoading();
            }
        });
    });

    // cambio del selector de competicion
    $(document).on('change', "#administracion_filtros #form_competicion", function (event) {
        mostrarLoadingById('.card-body');
        resetOptions('#administracion_filtros #form_jornada');
        resetOptions('#administracion_filtros #form_partido');
        $competicion = $(this).val();
        $.ajax({
            type: "POST",
            url: route_administracionEvaluacionesFiltros,
            data: {
                'competicion': $competicion
            },
            success: function (data) {
                var jornadasOptions = data.jornadas;
                addOptions('#administracion_filtros #form_jornada', jornadasOptions);
                $('#administracion_filtros #form_jornada').removeAttr('readonly');
                filtrarStatus();
                ocultarLoading();
            }
        });
    });

    // cambio del selector de jornada
    $(document).on('change', "#administracion_filtros #form_jornada", function (event) {
        mostrarLoadingById('.card-body');
        resetOptions('#administracion_filtros #form_partido');
        $jornada = $(this).val();
        $.ajax({
            type: "POST",
            url: route_administracionEvaluacionesFiltros,
            data: {
                'jornada': $jornada
            },
            success: function (data) {
                var partidosOptions = data.partidos;
                addOptions('#administracion_filtros #form_partido', partidosOptions);
                $('#administracion_filtros #form_partido').removeAttr('readonly');
                filtrarStatus();
                ocultarLoading();
            }
        });
    });

    // cambio del selector de partido
    $(document).on('change', "#administracion_filtros #form_partido", function (event) {
        filtrarStatus();
    });

    //---------------------------------------------
    // END Filtros Administracion Evaluaciones
    //---------------------------------------------


    // Inicializar los sliders de los inputs de tipo NotaType
    initSliders();


    // collapses: cambio de icono.
    $('div.collapse').on('shown.bs.collapse', function (){
        cambiarIcono(this, 'shown');
    });

    $('div.collapse').on('hidden.bs.collapse', function (){
        cambiarIcono(this, 'hidden');
    });

    ///////////////////////////////////////////////////
    // Enviar formulario seguimiento de evaluaciones
    //////////////////////////////////////////////////
    $(document).on('click', 'a.btn-exportar', function (e) {
        e.preventDefault();
        var $this = $(this);
        var idCompeticion = $this.data('id');
        var idJornada =  $('#jornadas-' + idCompeticion ).val();

        enviarFormularioSeguimientoEvaluaciones(idCompeticion, idJornada);
    });

    //---------------------------------------------
    // Image upload preview
    //---------------------------------------------
    const veissImages = document.querySelectorAll('.img-uploader');
    if (veissImages.length > 0) {
        veissImages.forEach((veissImage) => {
            new VeissImageHandler(veissImage);
        });
    }

    //---------------------------------------------
    // Image delete btn
    //---------------------------------------------
    const buttons = document.querySelectorAll('.btn-delete');
    if (buttons.length > 0) {
        buttons.forEach((button) => {
            const image = button.nextElementSibling.querySelector('img');
            if (image && image.src !== image.dataset.dummy) {
                button.classList.remove('d-none');
            }

            // Delete and hide on click
            button.addEventListener('click', function(e) {
                e.preventDefault();

                this.classList.add('d-none');

                // Set dummy image src
                const image = this.nextElementSibling.querySelector('img');
                if (image) {
                    image.src = image.dataset.dummy;

                    const container = image.closest('.container');
                    if (container) {
                        // Empty imput
                        const fileInput = container.querySelector('input');
                        if (fileInput) {
                            fileInput.value = '';
                        }

                        // Clear label
                        const fileLabel = container.querySelector('label');
                        if (fileLabel) {
                            fileLabel.innerHTML = fileLabel.dataset.label;
                        }
                    }
                }

                // Mark for deletion
                const checkInput = this.querySelector('input');
                if (checkInput) {
                    checkInput.checked = true;
                }
            }, false);
        });
    }
});


function enviarFormularioSeguimientoEvaluaciones(idCompeticion, idJornada)
{
    var $form = $('#seguimiento-evaluaciones-export');
    $form.find('input[name="competicion"]').val(idCompeticion);
    $form.find('input[name="jornada"]').val(idJornada);
    $form.submit();
    return;

}

/**
 * Cambia los iconos asociados segun el estado del collapse.
 */
function cambiarIcono(target, action) {
    var downClass = 'fa-chevron-down';
    var upClass = 'fa-chevron-up';
    var removeClass, addClass;

    // buscar icono
    var idCollapse = "#" + $(target).attr('id');
    var aSelector = "a[data-target='" + idCollapse + "']";
    $icon = $(aSelector).find('i');

    // cambiar icono
    if (action == 'shown') {
        removeClass = downClass;
        addClass = upClass;
    } else {
        removeClass = upClass;
        addClass = downClass;
    }

    $icon.removeClass(removeClass).addClass(addClass);
}


function addData(chart, label, data) {
    chart.data.labels.push(label);
    chart.data.datasets.forEach(function (dataset) {
        dataset.data.push(data);
    });
    chart.update();
}

function resetOptions(elementSelectorString) {
    var select = $(elementSelectorString);
    if (select.prop) {
        var options = select.prop('options');
    } else {
        var options = select.attr('options');
    }
    $('option', select).remove();

    // opcion vacia
    options[options.length] = new Option(selectOptionEmptyText, "");
    $(elementSelectorString).attr('readonly', true);

}

function filtrarStatus() {

    var $jornada = $('#administracion_filtros #form_jornada');
    var $partido = $('#administracion_filtros #form_partido');
    var jornadaSelected = ($jornada.val()) ? true : false;
    var partidoSelected = ($partido.val()) ? true : false;

    var $filtrar_btn = $('#administracion_filtros #form_filtrar');
    var $filtrar_btn_div = $filtrar_btn.parent();

    if (jornadaSelected && partidoSelected) {
        $filtrar_btn.removeAttr('disabled').removeClass('disabled');
        $filtrar_btn_div.removeClass('btn-filter-disabled');
    } else {
        $filtrar_btn.attr('disabled', true).addClass('disabled');
        $filtrar_btn_div.addClass('btn-filter-disabled');
    }

}

function addOptions(elementSelectorString, newOptions) {
    var select = $(elementSelectorString);
    if (select.prop) {
        var options = select.prop('options');
    } else {
        var options = select.attr('options');
    }
    $('option', select).remove();

    // opcion vacia
    options[options.length] = new Option(selectOptionEmptyText, "");

    $.each(newOptions, function (val, text) {
        options[options.length] = new Option(text, val);
    });
}

function mostrarLoadingById(identificador) {
    var card = $(identificador).first();
    cardLoading.loadingOn(card);
}

function mostrarLoadingEvaluacion() {
    var card = $('#formLayout .card').first();
    cardLoading.loadingOn(card);
}

function ocultarLoading() {
    cardLoading.loadingOffAll();
}

//--------------------------------------
// Modales
//--------------------------------------
(function ($) {
    $.fn.gestoModal = function (options) {

        var settings = $.extend({

            modal: "#delete-confirm", //ID de la modal
            title: "", //Título de la modal
            content: "", //Contenido de la modal
            url: "#" //Url a la que va

        }, options);

        var $modal = $(settings.modal);

        $modal.find('.modal-title').text(settings.title);
        $modal.find('.modal-body').html(settings.content);

        $modal.find('.btn-action').unbind('click').bind('click', function () {

            window.location.href = settings.url;

        })

        $modal.modal();

    };


    //---------------------------------------------
    // Talleres Formativos:
    // Ocultar el campo de selector de equipo cuando es
    // para el colectivo arbitral
    //---------------------------------------------
    $("input[name='gesto_taller_formativo[colectivoArbitral]']").on('change', function () {
        var $equipoContainer = $('#equipo-container');
        if($(this).val() > 0) {
            $equipoContainer.hide();
            $('.select2entity[data-autostart="true"]').val(null).trigger('change');
        } else {
            $equipoContainer.show();
        }
    });

}(jQuery));

//---------------------------------------------
// Loading en las cards
//---------------------------------------------
function gestoLoading() {

    this.template = '<div class="card-loading"><div>';
    this.cards = [];
    this.d = new Date();

}

gestoLoading.prototype.loadingOn = function (card) {

    var t = this.d.getTime();

    var data_active = card.data('loading');

    if (typeof data_active == typeof undefined || data_active == false) {

        card.attr('data-loading', 'card-loading-' + t);

        card.append(this.template);

        this.cards.push('card-loading-' + t);

    }

}

gestoLoading.prototype.loadingOff = function (card) {

    var loading_id = card.attr('data-loading');

    var card_index = this.cards.indexOf(loading_id);

    card.find('.card-loading').remove();

    card.removeAttr('data-loading');

    this.cards.splice(card_index, 1);

}

gestoLoading.prototype.loadingOffAll = function () {

    var thisCache = this;

    this.cards.forEach(function (attr) {

        var card = $('[data-loading="' + attr + '"]');

        thisCache.loadingOff(card);

    });

}

//-----------------------------------------------------------------------
// JS media queries
//-----------------------------------------------------------------------

function deviceDetect() {

    this.device = null;

    var cThis = this;

    //Resize eventos
    this.mq_mobile = window.matchMedia("(min-width: 576px) and (max-width: 767px)");
    this.mq_mobile.addListener(function (mql) {
        if (mql.matches) {
            cThis.device = 'mobile';
        }
    });

    this.mq_tablet = window.matchMedia("(min-width: 768px) and (max-width: 991px)");
    this.mq_tablet.addListener(function (mql) {
        if (mql.matches) {
            cThis.device = 'tablet';
        }
    });

    this.mq_desktop = window.matchMedia("(min-width: 992px) and (max-width: 1199px)");
    this.mq_desktop.addListener(function (mql) {
        if (mql.matches) {
            cThis.device = 'desktop';
        }
    });

    this.mq_desktopxl = window.matchMedia('(min-width: 1200px)');
    this.mq_desktopxl.addListener(function (mql) {
        if (mql.matches) {
            cThis.device = 'desktoplg';
        }
    });

    //Cuando se carga la página
    if (this.mq_desktopxl.matches) {
        this.device = 'desktoplg';
    } else if (this.mq_desktop.matches) {
        this.device = 'desktop';
    } else if (this.mq_tablet.matches) {
        this.device = 'tablet';
    } else if (this.mq_mobile.matches) {
        this.device = 'mobile';
    } else {
        this.device = 'mobilesmall';
    }

}

//-----------------------------------------------------------------------
// Date converter
//-----------------------------------------------------------------------
function dateConverter() {};

dateConverter.prototype.frontToBack = function (date_string) {

    this.d = new Date(date_string);
    this.month = '' + (this.d.getMonth() + 1);
    this.day = '' + this.d.getDate();
    this.year = this.d.getFullYear();

    return [this.year, this.month, this.day].join('-');

}

dateConverter.prototype.backToFront = function (date_string) {

    this.d = new Date(date_string);
    this.month = '' + (this.d.getMonth() + 1);
    if (this.month.length < 2) {
        this.month = '0' + this.month
    };
    this.day = '' + this.d.getDate();
    if (this.day.length < 2) {
        this.day = '0' + this.month
    };
    this.year = this.d.getFullYear();

    return [this.day, this.month, this.year].join('/');

}

//-----------------------------------------------------------------------
// Gráficas
//-----------------------------------------------------------------------
function gestoChart(id, type, data, maxY) {

    this.chart;
    this.canvas_id = document.getElementById(id);
    this.chart_type = type;
    this.chart_data = data;
    this.chart_options = {
        title: {
            display: false,
            text: ''
        },
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    suggestedMax: maxY
                }
            }],
            xAxes: [{
                ticks: {
                   autoSkip: false
                }
            }]
        }
    }

    this.drawChart();

}

gestoChart.prototype.drawChart = function () {

    this.chart = new Chart(this.canvas_id, {
        type: this.chart_type,
        data: this.chart_data,
        options: this.chart_options
    });

}


////////////////////////////////////
// NotaType - js
////////////////////////////////////
function initSliders() {
    var slidersContainer = document.querySelectorAll("div[data-class='noui-slider']");
    Array.prototype.forEach.call(slidersContainer, function (item) {
        new gestoNoUiSlider(item);
    });
}


function gestoNoUiSlider(element) {

    this.id = element.getAttribute('id');
    this.min = element.getAttribute('data-min');
    this.max = element.getAttribute('data-max');
    this.value = element.getAttribute('data-value');
    this.slider = document.getElementById(this.id);
    this.pairElementId = element.getAttribute('data-id');
    this.pairElement = document.getElementById(this.pairElementId);

    // bloque conductas violentas asociadas.
    this.conductasViolentasElementId = element.getAttribute('data-conductasviolentas');
    this.conductasViolentasElement = document.getElementById(this.conductasViolentasElementId);

    // bloque conductas deportivas asociadas.
    this.conductasDeportivasElementId = element.getAttribute('data-conductasdeportivas');
    this.conductasDeportivasElement = document.getElementById(this.conductasDeportivasElementId);

    this.createSlider();
    this.bindPairAndAddEvents();

}

gestoNoUiSlider.prototype.createSlider = function() {

    var range_all_sliders = {
        'min': parseInt(this.min),
        '5': [ 5, 1 ],
        'max': parseInt(this.max)
    };

    noUiSlider.create(this.slider, {
        start: this.value,
        connect: 'lower',
        step: 1,
        range: range_all_sliders,
        pips: {
            mode: 'range',
            density: 10,
            stepped: true
        }
    });
}

gestoNoUiSlider.prototype.bindPairAndAddEvents = function() {

    var pairElement = this.pairElement;
    var slider = this.slider;
    var noUiConnect = slider.getElementsByClassName('noUi-connect')[0];
    var conductasDeportivasElement = this.conductasDeportivasElement;
    var conductasViolentasElement = this.conductasViolentasElement;

    this.slider.noUiSlider.on('update', function (values, handle) {
        var value = values[handle];
        pairElement.value = parseInt(value);

        // update color
        var background;
        if (value <= 4) {
            background = '#d9534f'
        } else if (value > 4 && value <= 7) {
            background = '#f0ad4e'
        } else {
            background = '#5cb85c'
        }

        noUiConnect.style.background = background;

        // mostrar | ocultar conductas violentas
        if (conductasViolentasElement != null) {
            if (value > 4) {
                if (!conductasViolentasElement.classList.contains('d-none')) {
                    conductasViolentasElement.classList.add('d-none');
                }
            } else {
                if (conductasViolentasElement.classList.contains('d-none')) {
                    conductasViolentasElement.classList.remove('d-none');
                }
            }
        }

        // mostrar | ocultar conductas deportivas
        if (conductasDeportivasElement != null) {
            if (value < 9) {
                if (!conductasDeportivasElement.classList.contains('d-none')) {
                    conductasDeportivasElement.classList.add('d-none');
                }
                // reset checkboxes
                /*var inputs = conductasDeportivasElement.getElementsByTagName('input');
                for (var i = 0; i < inputs.length; i++) {
                    var input = inputs[i];
                    if (input.type == 'checkbox' && input.checked) {
                        console.log(input);
                        input.checked = false;
                    }
                }*/
            } else {
                if (conductasDeportivasElement.classList.contains('d-none')) {
                    conductasDeportivasElement.classList.remove('d-none');
                }
            }
        }

    });

    // indice deportividad del partido.
    this.slider.noUiSlider.on('set', function (values, handle)  {
        // actualizar nivel deportividad
        actualizarNivelDeportividad(this);

        // calcular nuevo valor indice deportividad partido.
        recalcularIndiceDeportividadPartido();
    });

    this.pairElement.addEventListener('change', function () {
        slider.noUiSlider.set([this.value]);
    });
}
////////////////////////////////////
// Conductas deportivas y violentas (bloques checks de las notas en las evaluaciones)
////////////////////////////////////
/**
 * resetea los errores de los bloques de conductas(checks conductas violentas y deportivas)
 * que pueda haber en el formulario
 */
function resetErroresBloquesConductas() {
    var errorElements = document.getElementsByClassName('error-bloque-conducta');
    while(errorElements[0]) {
        errorElements[0].parentNode.removeChild(errorElements[0]);
    }​
}

/**
 * Valida el numero de checks necesarios en los bloques de conductas deportivas o violentas
 * dependiendo de la nota dada.
 */
function validarBloquesDeConductas() {

    // validacion para las conductas deportivas.
    var notasInputs = document.querySelectorAll('input[class*="noui-slider"]');
    var errores = [];
    var value;
    var numCheckedOk;
    var conductasElement;
    var inputs;
    var input;
    var numChecked,conductasDeportivasElementId;
    var conductasViolentasElementId;
    var tipoError;
    var errorMsg;

    notasInputs.forEach(function(inputElement, index) {
        conductasDeportivasElementId = inputElement.getAttribute('conductasdeportivas');
        conductasViolentasElementId = inputElement.getAttribute('conductasviolentas');

        // se trata de un campo con checks de conductas violentas o deportivas
        if (conductasDeportivasElementId != null || conductasViolentasElementId != null) {
            tipoError = 0;
            value = inputElement.value;

            /**
             * VALIDAR CHECKS de CONDUCTAS
             *
             * validar conductas violentas si la nota es inferior 5 o las conductas deportivas
             * si la nota es superior a 8.
             */
            if (value < 5) {
                // numero de checks necesarios para que este ok dependiendo de la nota
                numCheckedOk = 1;
                conductasElement = document.getElementById(conductasViolentasElementId);
                tipoError = 1;
            } else if (value > 8) {
                // numero de checks necesarios para que este ok dependiendo de la nota (9 minimo 3 y 10 minimo 4)
                numCheckedOk = (value == 9 ) ? 3 : 4 ;
                conductasElement = document.getElementById(conductasDeportivasElementId);
                tipoError = 2;
            }

            inputs = conductasElement.getElementsByTagName('input');
            numChecked = 0;
            for (var i = 0; i < inputs.length; i++) {
                input = inputs[i];
                if (input.type == 'checkbox' && input.checked) {
                    numChecked++;
                }
            }

            if (numChecked < numCheckedOk) {
                errorElementId = (tipoError == 1) ? conductasViolentasElementId : conductasDeportivasElementId ;
                errorMsg = "Seleccionar mínimo " + numCheckedOk + " opciones."
                errores.push({"elementId": errorElementId, "msg": errorMsg});
            }

        }
    });

    return errores;
}

/**
 * Añade los errores a los bloques de conductas deportivas.
 * Devuelve el id del bloque correspondiente al primer error encontrado
 */
function mostrarErroresBloquesConductas(errores) {

    for (var i = 0; i < errores.length; i++) {
        var error = errores[i];
        var element = document.getElementById(error.elementId);
        var elementCardHeader = element.getElementsByClassName("card-header").item(0);
        elementCardHeader.innerHTML += '<p class="error-bloque-conducta mt-2 mb-0 text-danger">' + error.msg + '</p>';
        if (i == 0) {
            var firstErrorId = error.elementId
        }
    }

    return firstErrorId;

}

////////////////////////////////////
// End  Conductas deportivas
////////////////////////////////////

//////////////////////////////////////////////////////////////////////
// Indice deportividad partido (cambio notas recalcular)
//////////////////////////////////////////////////////////////////////
function recalcularIndiceDeportividadPartido() {
    var $bloqueNotificaciones = $('#calificaciones');
    var $calificaciones = $bloqueNotificaciones.find('input[class*="noui-slider"]');
    var notas = {};

    var $this, id, value;
    $calificaciones.each(function(){
        $this = $(this);
        id = $this.attr('id');
        value = $this.val();
        if (!(id in notas)) {
            notas[id] = value;
        }
    });

    //console.log(notas, route_calcularIndiceDeportividadPartido);
    $.ajax({
        type: "POST",
        url: route_calcularIndiceDeportividadPartido,
        dataType: 'json',
        data: {
            'notas': notas
        },
        success: function (data) {

            var notaPartido = data.indiceDeportividad;
            var $partidoViolentoElement = $('#partido_violento');
            var $conductasViolentasElement = $('#conductasViolentas');

            // valor al campo hidden de calificacion del partido
            $('#gesto_evaluacion_calificacionPartido').val(notaPartido);

            // @TODO: Temporal, Despues van separados en bloques y asociados a cada nota, igual que las conductas deportivas
            // Mostrar campos de juego violento en el formulario de evaluar
            if ($.inArray(notaPartido, indicadorJuegoViolento) > -1) {
                if ($conductasViolentasElement.hasClass('d-none')) {
                    $conductasViolentasElement.removeClass('d-none');
                }
            } else {
                if (!$conductasViolentasElement.hasClass('d-none')) {
                    $conductasViolentasElement.addClass('d-none');
                }
            }

            // mostrar|ocultar check partido especialmente violento (Solo para arbitros)
            if ($partidoViolentoElement) {
                //console.log($partidoViolentoElement, notaPartido, $.inArray(notaPartido, indicadorEspecialViolencia));
                if ($.inArray(notaPartido, indicadorEspecialViolencia) > -1) {
                    if ($partidoViolentoElement.hasClass('d-none')) {
                        $partidoViolentoElement.removeClass('d-none');
                    }
                } else {
                    if (!$partidoViolentoElement.hasClass('d-none')) {
                        $partidoViolentoElement.addClass('d-none');
                    }
                }
            }
        },
        error: function(xhr, status, error) {
            console.log("¡Ocurrio un error!");
            console.log(JSON.parse(xhr.responseText));
        }
    });
}

//////////////////////////
// Nivel deportividad
//////////////////////////
function actualizarNivelDeportividad(element) {
    var nota = parseInt(element.get());
    var target = element.target;
    var $target = $(target);
    var idNivelDeportividad = "nivel-deportividad-" + $target.data('id');

    $.ajax({
        type: "POST",
        url: route_calcularNivelDeportividad,
        dataType: 'json',
        data: {
            'nota': nota
        },
        success: function (data) {
            $('#' + idNivelDeportividad).html(data.nivelDeportividadHtml);
        },
        error: function(xhr, status, error) {
            console.log("¡Ocurrio un error!");
            console.log(JSON.parse(xhr.responseText));
        }
    });
}
