(function ($) {
    $.fn.veissModal = function (options, element) {
        var settings = $.extend({
            id: '',
            class: '',
            buttons: [],
        }, options);

        element = $(element || this.defaultElement || this)[0];
        this.element = $(element);
        var that = this,
            buttons = settings.buttons,
            id = settings.id
        title = $('#' + id + ' div.title'),
            body = $('#' + id + ' div.body')
        contentClass = 'modal-content';

        // Limpiar las clases del modal y asignar las recibidas.
        if ('' != settings.class) {
            contentClass += ' ' + settings.class;
        }
        this.find('.modal-content').attr('class', contentClass);

        // Título, contenido y pie del modal.
        (0 == title.length) ? this.find('.modal-header').hide(): this.find('.modal-header').show();
        (0 == body.length) ? this.find('.modal-body').hide(): this.find('.modal-body').show();
        this.find('.modal-header').html(title.html());
        this.find('.modal-body').html(body.html());
        this.find('.modal-footer').empty();

        // Botones del modal.
        $.each(buttons, function (name, props) {
            var buttonDiv = $('#' + id + ' div.' + props.id);

            if (0 == buttonDiv.length) {
                return; // Equivalente a continue en PHP.
            }

            props.text = buttonDiv.html();

            var click, buttonOptions;
            props = $.isFunction(props) ? {
                    click: props,
                    text: name
                } :
                props;
            props = $.extend({
                type: 'button'
            }, props);

            click = props.click;
            if (undefined != click) {
                props.click = function () {
                    click.apply(that.element[0], arguments);
                };
            }

            buttonOptions = {
                icons: props.icons,
                text: props.showText
            };

            delete props.icons;
            delete props.showText;

            props['data-dismiss'] = 'modal';

            $('<button></button>', props)
                .addClass('btn')
                .button(buttonOptions)
                .appendTo(that.find('.modal-footer'));
        });

        // Al cerrar el modal, hacer scroll a la parte superior del contenido.
        this.on('hide.bs.modal', function () {
            $(element).find('.modal-body').scrollTop(0);
        });

        this.modal('show');
    };
}(jQuery));